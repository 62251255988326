import React from "react";

const Input = ({
  type = "text",
  className = "form-control",
  setValue = null,
  value = "",
  ...rest
}) => {
  return (
    <input
      type={type}
      onChange={e => {
        if (setValue) {
          setValue(e.target.value);
        }
      }}
      value={value}
      className={className}
      {...rest}
    />
  );
};

export const NumberInput = ({
  name = "",
  className = "form-control",
  setValue = null,
  value = "",
  ...rest
}) => {
  const onKeyPress = event => {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      event.preventDefault();
      return;
    }
  };

  return (
    <input
      type="tel"
      className={className}
      onKeyPress={onKeyPress}
      value={value}
      onChange={e => {
        if (setValue) {
          setValue(e.target.value);
        }
      }}
      {...rest}
    />
  );
};

export default Input;
