import axios from "axios";

const { CancelToken, create, isCancel } = axios;

const apiInstance = create({
  baseURL: "https://emailer.billiontags.com/"
});

export { CancelToken, isCancel };

export default apiInstance;
